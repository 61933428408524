.containerWithImage {
  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 72px;
  padding: 24px;

  background-image: url("../../assets/images/pc.png");
  background-repeat: no-repeat;
  background-position: top 90px right -25px;
  background-size: 356px, auto, cover;

  @media (max-width: 768px) {
    height: 100%;
    padding: 32px 24px;
    gap: 16px;
    background-image: unset;
  }
}

.topBlock {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    cursor: pointer;
  }
}

.leftBlock {
  display: flex;
  align-items: center;
  gap: 6px;

  span {
    font-size: 26px;
    line-height: 130%;
    font-family: "Poppins6", sans-serif;
    letter-spacing: -0.045em;
  }
}

.name {
  font-family: "Poppins6", sans-serif;
  font-size: 24px;
  line-height: 120%;

  span {
    background: var(--green-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.nameSended {
  margin-top: 79px;

  @media (max-width: 768px) {
    margin-top: 8px;
  }
}

.text {
  font-size: 16px;
  line-height: 130%;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    margin-bottom: 99px;
  }
}

.inputsBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 327px;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.button {
  width: 327px;
  height: 48px;
  border-radius: var(--br-main);
  font-family: "Poppins7", sans-serif;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green-gradient);
  color: var(--main-white);
  transition: all .2s ease-out;

  &:disabled {
    color: var(--main-black);
    opacity: 0.3;
    border: 1px solid var(--main-black);
    border-radius: 12px;
    background: inherit;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
}

.buttonSended {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  width: 327px;
  height: 48px;
  min-height: 48px;
  border-radius: var(--br-main);
  font-family: "Poppins6", sans-serif;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  background: conic-gradient(from 134.31deg at 50% 50%, #82C43C 0deg, #04C000 333.75deg, #82C43C 360deg);
  -webkit-background-clip: text, padding-box;
          background-clip: text, padding-box;
  color: #1DAC1A;
  position: relative;
  transition: all .2s ease-out;

  @media (max-width: 768px) {
    width: 100%;
  }

  &::before {
  content:"";
  position:absolute;
  inset: 0;
  border: 2px solid transparent;
  border-radius: var(--br-main);
  background: inherit;
  background-origin: border-box;
  background-clip: border-box;
  -webkit-mask:
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  -webkit-mask-repeat: no-repeat;
}
}

.pc {
  max-height: 310px;
  object-fit: contain;
  height: calc(100% - 396px);
}
