:root {
  //colors
  --main-black: #313131;
  --main-white: #FFFFFF;
  --main-gray: #FAFAFA;
  --second-gray: #F8F7F6;

  //gradients
  --green-gradient: conic-gradient(from 134.31deg at 50% 50%, #82C43C 0deg, #04C000 333.75deg, #82C43C 360deg);

  //border-radius
  --br-main: 10px;
  --br-small-section: 20px;
  --br-section: 25px;
}
