@font-face {
    font-family: 'Calibre4';
    src: url('../fonts/Calibre-Regular.woff2') format('woff2'),
    url('../fonts/Calibre-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Calibre6';
    src: url('../fonts/Calibre-Semibold.woff2') format('woff2'),
    url('../fonts/Calibre-Semibold.woff') format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins4';
    src: url("../fonts/Poppins-Regular.woff2") format('woff2'),
    url("../fonts/Poppins-Regular.woff") format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins6';
    src: url("../fonts/Poppins-SemiBold.woff2") format('woff2'),
    url("../fonts/Poppins-SemiBold.woff") format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins7';
    src: url("../fonts/Poppins-Bold.woff2") format('woff2'),
    url("../fonts/Poppins-Bold.woff") format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}
