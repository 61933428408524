.container {
    position: relative;
}

.input {
    padding: 12px 16px 12px 52px;
    height: 48px;
    border: 1px solid rgba(50, 50, 50, 0.3);
    border-radius: 12px;
    font-size: 14px;
    line-height: 160%;
    display: flex;
    align-items: center;
    width: 100%;
    font-family: "Popins4", sans-serif;

    &::placeholder {
        opacity: .3;
        display: flex;
        align-items: center;
        align-self: center;
        justify-content: center;
        justify-self: center;
    }
}

.image {
    position: absolute;
    top: 12px;
    left: 16px;
}
