@import "./root", "./fonts", "./reset.css";

html, body{
  width: 100vw;
  overflow-x: hidden;
}

body {
  font-family: "Calibre4", sans-serif;
  -webkit-font-smoothing: antialiased; ///зменшує жирність на сафарі
  background-color: #FAFAFA;
  font-size: 16px;
  line-height: 130%;
  color: var(--main-black);
  overflow-y: scroll;
  overflow-y: overlay;

  scrollbar-width: thin;
  scrollbar-color: #82C43C #FAFAFA;

  &::-webkit-scrollbar-thumb:hover {
    background-color: #04C000;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #FAFAFA;
  }

  &::-webkit-scrollbar-thumb {
    //border: 3px solid #FAFAFA;
    border-radius: 100px;
    background-color: #82C43C;
    background-clip: content-box;
    transition: all 1s ease-out;
  }

  //@media (max-width: 1100px) {
  //  background-color: var(--main-white)
  //}
}

.gs_reveal {
  //opacity: 0;
  //visibility: hidden;
  //will-change: transform, opacity;
}
