.popup-content {
    width: 690px;
    background: #F8F7F6;
    box-shadow: 0 16px 37px rgba(0, 0, 0, 0.03);
    border-radius: 24px;
    overflow: hidden;
    -webkit-animation: anvil .3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    @media(max-width: 768px) {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        margin: 0;
    }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-overlay {
  background-color: rgb(181 181 181 / 80%);

  @media (max-width: 768px) {
    background-color: var(--second-gray);
  }
}
